import { Suspense, lazy, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from './components/Loading';

const routes = [
  {
    exact: true,
    path: '/',
    component: lazy(() => import('./pages/Home'))
  },
  {
    exact: true,
    path: '/bsc',
    component: () => {
      window.location = 'https://legacy.wisetoken.net/bsc';
    }
  },
  {
    exact: true,
    path: '/docs',
    component: () => {
      window.location = 'https://docs.wisetoken.net/';
    }
  },
  {
    exact: true,
    path: '/dashboard',
    component: () => {
      window.location = 'https://app.wisetoken.net/';
    }
  },
  {
    exact: true,
    path: '/staking',
    component: () => {
      window.location = 'https://app.wisetoken.net/staking';
    }
  },
  {
    exact: true,
    path: '/bulk',
    component: () => {
      window.location = 'https://app.wisetoken.net/bulk';
    }
  },
  {
    exact: true,
    path: '/refer',
    component: () => {
      window.location = 'https://app.wisetoken.net/refer';
    }
  },
  {
    exact: true,
    path: '/swaps',
    component: () => {
      window.location = 'https://app.wisetoken.net/swaps';
    }
  },
  {
    path: '*',
    component: () => {
      window.location.pathname = '/';
      return lazy(() => import('./pages/Home'));
    }
  }
];

export const renderRoutes = (routes_) => {
  // redirect bsc subdomain to new legacy URL
  const host = window.location.host;
  const parts = host.split('.');
  if (parts.length >= 2 && parts[0] === 'bsc') {
    window.location = 'https://legacy.wisetoken.net/bsc';
    return;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {routes_.map((route, i) => {
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default routes;
