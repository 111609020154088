export const WriteReferralCookie = (ref) => {
  let domain;
  if (!/^0x[a-fA-F0-9]{40}$/g.test(ref)) return;
  const host = window.location.hostname;
  if (host && host[0] !== '[' && !Number.parseInt(host[0], 10)) {
    domain = host.substring(
      host.lastIndexOf('.', host.lastIndexOf('.') - 1) + 1
    );
  } else domain = host;
  document.cookie =
    'w=' +
    ref +
    ';domain=' +
    domain +
    ';path=/;expires=Sat, 28 Mar 2037 00:00:00 GMT;max-age=536479200;';
};

const HandleReferralQueryString = () => {
  let start;
  const search = window.location.search;
  if (search.startsWith('?w=')) {
    start = 3;
  } else {
    start = search.indexOf('&w=');
    if (start < 0) return;
    start += 3;
  }
  const end = search.indexOf('&', start);
  const ref = decodeURIComponent(
    search.substring(start, end >= 0 ? end : undefined)
  );
  if (ref) {
    if (window === window.top) {
      WriteReferralCookie(ref);
    }
    window.history.replaceState(
      {},
      null,
      window.location.pathname + window.location.hash
    );
    window.location.replace('https://app.wisetoken.net');
  }
};

export default HandleReferralQueryString;
