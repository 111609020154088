import { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import BinanceContext from '../../context/binance';
import ThemeContext from '../../context/theme';
import routes, { renderRoutes } from '../../routes';
import HandleReferralQueryString from '../../utils/ReferralCookie';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';

const history = createBrowserHistory();

HandleReferralQueryString();

const App = () => {
  const [type, setType] = useState('ETHEREUM');
  const value = { type, setType };

  const [theme, setTheme] = useState(
    localStorage.theme === 'dark' ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
      ? 'dark'
      : 'light'
  );
  const themeValue = { theme, setTheme };

  return (
    <ThemeContext.Provider value={themeValue}>
      <BinanceContext.Provider value={value}>
        <Router history={history}>{renderRoutes(routes)}</Router>
      </BinanceContext.Provider>
    </ThemeContext.Provider>
  );
};

export default App;
