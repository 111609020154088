import { createContext } from 'react';

const ThemeContext = createContext({
  theme:
    localStorage.theme === 'dark' ||
    (!('theme' in localStorage) &&
      window.matchMedia('(prefers-color-scheme: dark)').matches)
      ? 'dark'
      : 'light',
  setTheme: () => {}
});

export default ThemeContext;
